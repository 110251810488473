// const axios = require('axios');
import axios from 'axios';
import { history } from '../history';

let baseURL = 'https://api-bo.pixscobranca.com.br';
if (window.location.hostname === 'dashboard-sandbox.pixscobranca.com.br') {
  baseURL = 'https://api-bo-sandbox.pixscobranca.com.br';
}

const instance = axios.create({
  baseURL: baseURL,
  // baseURL: 'http://localhost:5008/api',
  // timeout: 5000,
  withCredentials: true
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      history.push('/plataforma/login');
    }
    return Promise.reject(error.response);
  }
);

// module.exports = instance;
export default instance;
