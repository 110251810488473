import React from 'react';
import * as Icon from 'react-feather';
import { Icon as Iconify } from '@iconify/react';

const navigationConfig = [
  {
    id: 'home',
    title: 'Início',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/home'
  },
  {
    id: 'employeeHome',
    title: 'Dashboard',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['employee'],
    navLink: '/plataforma/employee/home'
  },
  {
    id: 'activity',
    title: 'Atividade',
    type: 'item',
    icon: <Icon.AlignLeft size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/atividade'
  },
  {
    id: 'deposit',
    title: 'Depositar',
    type: 'item',
    icon: <Icon.Download size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/depositar'
  },
  // {
  //   id: 'exchange',
  //   title: 'Converter',
  //   type: 'item',
  //   icon: <Icon.Repeat size={20} />,
  //   permissions: ['customer'],
  //   navLink: '/plataforma/converter'
  // },
  {
    id: 'withdraw',
    title: 'Sacar',
    type: 'item',
    icon: <Icon.Upload size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/sacar'
  },
  {
    id: 'bankAccounts',
    title: 'Contas Bancárias',
    type: 'item',
    icon: <Icon.Book size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/contas-bancarias'
  },
  // {
  //   id: "settings",
  //   title: "Configurações",
  //   type: "item",
  //   icon: <Icon.Settings size={20} />,
  //   permissions: ["customer"],
  //   navLink: "/configuracoes"
  // },
  {
    id: 'home',
    title: 'Início',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: [
      'client',
      'client_user',
      'client_user_admin',
      'client_user_analyst',
      'client_user_financial'
    ],
    navLink: '/plataforma/clients/home'
  },
  {
    id: 'metrics',
    title: 'Métricas',
    type: 'item',
    icon: <Icon.BarChart2 size={20} />,
    permissions: ['client', 'client_user_admin'],
    navLink: '/plataforma/clients/metrics'
  },
  {
    id: 'clientVolume',
    title: 'Resultado',
    type: 'item',
    icon: <Icon.TrendingUp size={20} />,
    permissions: ['client', 'client_user_admin'],
    navLink: '/plataforma/clients/volume'
  },
  {
    id: 'direct_deposit',
    title: 'Depósitos na Chave',
    type: 'item',
    icon: <Icon.DollarSign size={20} />,
    permissions: ['direct_deposits'],
    navLink: '/plataforma/clients/direct_deposits'
  },
  {
    id: 'deposit',
    title: 'Pagamentos',
    type: 'item',
    icon: <Icon.DollarSign size={20} />,
    permissions: [
      'client',
      'client_user',
      'client_user_admin',
      'client_user_analyst',
      'client_user_financial'
    ],
    navLink: '/plataforma/clients/payments'
  },
  {
    id: 'payouts',
    title: 'Saques',
    type: 'item',
    icon: <Icon.Download size={20} />,
    permissions: [
      'client',
      'client_user',
      'client_user_admin',
      'client_user_analyst',
      'client_user_financial'
    ],
    navLink: '/plataforma/clients/withdraws'
  },
  {
    id: 'clientManualPayouts',
    title: 'Saques manuais',
    type: 'item',
    icon: <Icon.SkipForward size={20} />,
    permissions: ['client', 'client_user_admin', 'client_user_financial'],
    featureToggles: ['manual_withdraws'],
    navLink: '/plataforma/clients/manual-withdraws'
  },
  {
    id: 'clientWalletWithdraws',
    title: 'Retiradas',
    type: 'item',
    icon: <Icon.ExternalLink size={20} />,
    permissions: ['client', 'client_user_admin'],
    navLink: '/plataforma/client/wallet-withdraws'
  },
  // {
  //   id: 'clientWalletDebts',
  //   title: 'Carteira',
  //   type: 'item',
  //   icon: <Icon.ExternalLink size={20} />,
  //   permissions: ['client', 'client_user_admin', 'client_user_financial'],
  //   navLink: '/plataforma/clients/external-debts'
  // },
  // {
  //   id: 'refunds',
  //   title: 'Estornos',
  //   type: 'item',
  //   icon: <Icon.MinusCircle size={20} />,
  //   permissions: ['client', 'client_user_admin', 'client_user_financial'],
  //   navLink: '/plataforma/clients/refunds'
  // },
  {
    id: 'clientuser',
    title: 'Usuários',
    type: 'item',
    icon: <Icon.UserPlus size={20} />,
    permissions: ['client', 'client_user_admin'],
    navLink: '/plataforma/clients/client-user'
  },
  // {
  //   id: 'integrations',
  //   title: 'Integrações',
  //   type: 'item',
  //   icon: <Icon.Code size={20} />,
  //   permissions: ['client', 'client_user_admin'],
  //   navLink: '/plataforma/clients/integrations'
  // },
  // {
  //   id: 'marketingSms',
  //   title: 'Sms',
  //   type: 'item',
  //   icon: <Icon.MessageSquare size={20} />,
  //   permissions: ['client', 'client_user_admin'],
  //   navLink: '/plataforma/clients/marketing-sms'
  // },
  // {
  //   id: 'marketingEmail',
  //   title: 'Email',
  //   type: 'item',
  //   icon: <Icon.Mail size={20} />,
  //   permissions: ['client', 'client_user_admin'],
  //   navLink: '/plataforma/clients/marketing-email'
  // },
  // {
  //   id: 'marketingWhatsapp',
  //   title: 'Whatsapp',
  //   type: 'item',
  //   icon: <Icon.MessageCircle size={20} />,
  //   permissions: ['client', 'client_user_admin'],
  //   navLink: '/plataforma/clients/marketing-whatsapp'
  // },
  // {
  //   id: 'support',
  //   title: 'Support',
  //   type: 'item',
  //   icon: <Icon.MessageCircle size={20} />,
  //   permissions: ['client'],
  //   navLink: '/clients/support',
  // },
  {
    id: 'clientConfig',
    title: 'Configurações',
    type: 'item',
    icon: <Icon.Settings size={20} />,
    permissions: ['client', 'client_user_admin'],
    navLink: '/plataforma/clients/config'
  },
  {
    id: 'admHome',
    title: 'Início',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/home'
  },
  {
    id: 'employeeTransactionsSearch',
    title: 'Transações',
    type: 'item',
    icon: <Iconify icon="mdi:cash-fast" width="20" height="20" />,
    permissions: ['admin', 'employee'],
    navLink: '/plataforma/employee/transactions'
  },
  {
    id: 'admReports',
    title: 'Relatórios',
    type: 'item',
    icon: <Icon.FileText size={20} />,
    permissions: ['admin', 'employee'],
    navLink: '/plataforma/admin/reports'
  },
  {
    id: 'admVolume',
    title: 'Resultado',
    type: 'item',
    icon: <Icon.TrendingUp size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/volume'
  },
  // {
  //   id: 'supportDepositsRefund',
  //   title: 'Reembolso de Depósito',
  //   type: 'item',
  //   icon: <Icon.RefreshCcw size={20} />,
  //   permissions: ['employee'],
  //   navLink: '/plataforma/employee/deposits_refund'
  // },
  {
    id: 'supportRefundRetry',
    title: 'Dev. Depósitos',
    type: 'item',
    icon: <Icon.RefreshCcw size={20} />,
    permissions: ['employee'],
    navLink: '/plataforma/employee/refund_retry'
  },
  // {
  //   id: 'supportDirectDeposits',
  //   title: 'Depósitos diretos',
  //   type: 'item',
  //   icon: <Icon.List size={20} />,
  //   permissions: ['employee'],
  //   navLink: '/plataforma/employee/direct_deposits'
  // },
  // {
  //   id: 'admAccounts',
  //   title: 'Gerais',
  //   type: 'item',
  //   icon: <Icon.BarChart2 size={20} />,
  //   permissions: ['admin'],
  //   navLink: '/plataforma/admin/overall'
  // },
  // {
  //   id: 'admInvoices',
  //   title: 'Notas Fiscais',
  //   type: 'item',
  //   icon: <Icon.Layers size={20} />,
  //   permissions: ['admin'],
  //   navLink: '/plataforma/admin/invoices'
  // },
  // {
  //   id: 'batchFiles',
  //   title: 'Batch Files',
  //   type: 'item',
  //   icon: <Icon.FilePlus size={20} />,
  //   permissions: ['admin'],
  //   navLink: '/plataforma/employee/payouts'
  // },
  // {
  //   id: 'admVolume',
  //   title: 'Volume',
  //   type: 'item',
  //   icon: <Icon.HardDrive size={20} />,
  //   permissions: ['admin'],
  //   navLink: '/plataforma/admin/volume'
  // },
  {
    id: 'admCompliance',
    title: 'KYC',
    type: 'collapse',
    icon: <Icon.AlertTriangle size={20} />,
    permissions: ['admin'],
    children: [
      {
        id: 'admPayoutsCompliance',
        title: 'Saques',
        type: 'item',
        icon: <Icon.AlertTriangle size={20} />,
        permissions: ['admin'],
        navLink: '/plataforma/admin/compliance'
      },
      {
        id: 'admRestictionList',
        title: 'Restrições',
        type: 'item',
        icon: <Icon.XOctagon size={20} />,
        permissions: ['admin'],
        navLink: '/plataforma/admin/restrictions'
      },
      {
        id: 'admCustomerList',
        title: 'Clientes',
        type: 'item',
        icon: <Icon.UserCheck size={20} />,
        permissions: ['admin'],
        navLink: '/plataforma/admin/customers-kyc'
      },
      {
        id: 'admCustomerCharts',
        title: 'Métricas',
        type: 'item',
        icon: <Iconify icon="bx:bar-chart-alt" width={20} height={20} />,
        permissions: ['admin'],
        navLink: '/plataforma/admin/customers-metrics'
      }
      // {
      //   id: 'admCustomersSummary',
      //   title: 'Depósitos',
      //   type: 'item',
      //   icon: <Iconify icon="bx:bar-chart-alt" width={20} height={20} />,
      //   permissions: ['admin'],
      //   navLink: '/plataforma/admin/customers-summary'
      // }
    ]
  },
  // {
  //   id: 'admExpenses',
  //   title: 'Despesas',
  //   type: 'item',
  //   icon: <Icon.ShoppingCart size={20} />,
  //   permissions: ['admin', 'expenses'],
  //   navLink: '/plataforma/admin/expenses'
  // },
  // {
  //   id: 'admCosts',
  //   title: 'Custos',
  //   type: 'item',
  //   icon: <Icon.List size={20} />,
  //   permissions: ['admin'],
  //   navLink: '/plataforma/admin/costs'
  // },
  {
    id: 'admFeeRates',
    title: 'Taxas',
    type: 'item',
    icon: <Icon.Percent size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/fee-rates'
  },
  // {
  //   id: 'admSettlements',
  //   title: 'Remessas',
  //   type: 'item',
  //   icon: <Icon.Circle size={20} />,
  //   permissions: ['admin'],
  //   navLink: '/plataforma/admin/settlements'
  // },
  // {
  //   id: 'admBalance',
  //   title: 'Balanço',
  //   type: 'item',
  //   icon: <Icon.TrendingUp size={20} />,
  //   permissions: ['admin'],
  //   navLink: '/plataforma/admin/balance'
  // },
  {
    id: 'admStatements',
    title: 'Conferencias',
    type: 'item',
    icon: <Icon.Columns size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/statements'
  },
  // {
  //   id: 'admBankAccounts',
  //   title: 'Contas e Carteiras',
  //   type: 'item',
  //   icon: <Icon.Book size={20} />,
  //   permissions: ['admin'],
  //   navLink: '/plataforma/admin/bank-accounts'
  // },
  {
    id: 'admEmployees',
    title: 'Funcionários',
    type: 'item',
    icon: <Icon.Users size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/employees'
  },
  {
    id: 'admClientsBalance',
    title: 'Clientes Saldos',
    type: 'item',
    icon: <Icon.Award size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/clients-balance'
  },
  {
    id: 'admWalletWithdraws',
    title: 'Retiradas Cart.',
    type: 'item',
    icon: <Icon.ExternalLink size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/wallet-withdraws'
  },
  {
    id: 'admClient',
    title: 'Clientes',
    type: 'item',
    icon: <Icon.UserPlus size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/clients'
  },
  {
    id: 'admBatchPayouts',
    title: 'Batch Payouts',
    type: 'item',
    icon: <Icon.Inbox size={20} />,
    permissions: ['batch_payouts_admin'],
    navLink: '/plataforma/admin/batch-payouts'
  },
  {
    id: 'returnFiles',
    title: 'Retorno',
    type: 'item',
    icon: <Icon.FileText size={20} />,
    permissions: ['return_files'],
    navLink: '/plataforma/return-files'
  },
  {
    id: 'deliveryFiles',
    title: 'Remessas',
    type: 'item',
    icon: <Icon.File size={20} />,
    permissions: ['delivery_files'],
    navLink: '/plataforma/delivery-files'
  },
  {
    id: 'deliveryFilesExcel',
    title: 'Remessas Excel',
    type: 'item',
    icon: <Icon.File size={20} />,
    permissions: ['delivery_files_excel'],
    navLink: '/plataforma/delivery-files-excel'
  },
  {
    id: 'nfseForm',
    title: 'NFS-e',
    type: 'item',
    icon: <Icon.Layers size={20} />,
    permissions: ['nfse_form'],
    navLink: '/plataforma/nfse-form'
  },
  {
    id: 'batchPayouts',
    title: 'Payouts',
    type: 'item',
    icon: <Icon.Layers size={20} />,
    permissions: ['batch_payouts'],
    navLink: '/plataforma/batch-payouts'
  },
  {
    id: 'internationalPayments',
    title: 'Cabos China',
    type: 'item',
    icon: <Icon.Circle size={20} />,
    permissions: ['international_payments'],
    navLink: '/plataforma/international-payments'
  },
  // {
  //   id: 'pagePartners',
  //   title: 'Parcerias',
  //   type: 'item',
  //   icon: <Iconify icon="mdi:partnership-outline" width={20} height={20} />,
  //   permissions: ['admin'],
  //   navLink: '/plataforma/admin/partners'
  // },

  {
    id: 'financialStatements',
    title: 'Conferencias',
    type: 'item',
    icon: <Icon.Columns size={20} />,
    permissions: ['financial'],
    navLink: '/plataforma/financial/statements'
  },
  {
    id: 'supportPhonesAndEmails',
    title: 'Telefones e Emails',
    type: 'item',
    icon: <Iconify icon="mdi:card-account-phone" width={20} height={20} />,
    permissions: ['employee', 'admin'],
    navLink: '/plataforma/employee/kyc-phones-emails'
  },
  {
    id: 'devolutions',
    title: 'Devoluções',
    type: 'item',
    icon: <Iconify icon="ooui:article-redirect-rtl" width={20} height={20} />,
    permissions: ['employee'],
    navLink: '/plataforma/employee/devolutions'
  },
  // {
  //   id: 'thirdPartyThreshold',
  //   title: 'D. Terceiros Limites',
  //   type: 'item',
  //   icon: <Iconify icon="mdi:alert-remove-outline" width={20} height={20} />,
  //   permissions: ['admin'],
  //   navLink: '/plataforma/thirdparty/threshold',
  //   disabled: true
  // },
  {
    id: 'partnersHome',
    title: 'Home',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['partner_providers'],
    navLink: '/plataforma/partner-providers/home'
  },
  {
    id: 'partnersDeposits',
    title: 'Depósitos',
    type: 'item',
    icon: <Iconify icon="mdi:cash-plus" width={20} height={20} />,
    permissions: [
      'partner',
      'partner_admin',
      'partner_support',
      'partner_analyst'
    ],
    navLink: '/plataforma/partner/deposits'
  },

  {
    id: 'partnersProvidersDeposits',
    title: 'Depósitos',
    type: 'item',
    icon: <Iconify icon="mdi:cash-plus" width={20} height={20} />,
    permissions: ['partner_providers'],
    navLink: '/plataforma/partner-providers/deposits'
  },
  {
    id: 'partnersWithdraws',
    title: 'Saques',
    type: 'item',
    icon: <Iconify icon="mdi:cash-minus" width={20} height={20} />,
    permissions: [
      'partner',
      'partner_admin',
      'partner_support',
      'partner_analyst'
    ],
    navLink: '/plataforma/partner/withdraws'
  },
  {
    id: 'partnersUsers',
    title: 'Usuários',
    type: 'item',
    icon: <Icon.Users size={20} />,
    permissions: ['partner', 'partner_admin'],
    navLink: '/plataforma/partner/users'
  },
  {
    id: 'restrictionsListEmployee',
    title: 'Restrições',
    type: 'item',
    icon: <Icon.XOctagon size={20} />,
    permissions: ['employee', 'client', 'client_user_admin'],
    navLink: '/plataforma/employee/restrictions'
  },
  {
    id: 'logsClients',
    title: 'Logs',
    type: 'item',
    icon: <Icon.AlignLeft size={20} />,
    permissions: ['client', 'client_user_admin'],
    navLink: '/plataforma/client/logs'
  }
];

export { navigationConfig };
